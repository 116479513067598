<template>
<div>
 <el-card style="width: 100%;height: 80vh">
   <el-select
       style="width: 220px"
       v-model="userId" placeholder="请选择管理员" size="medium"
   >
     <el-option :label="i.realName" :value="i.uid" v-for="i in authMemberList" />
   </el-select>
   <div style="color: #fda400;margin-top: 10px">
     令牌首次使用开始2小时内有有效,超过2小时有效期或超过3次使用次数,令牌将失效,无法在使用。
   </div>
 <div style="margin-bottom: 20px">
   <el-button type="primary" @click="getKey" style="margin-top: 20px">导出密钥</el-button>
   <el-button v-if="key" type="info" @click="handleRead">复制</el-button>
 </div>
   <div v-if="key" >
     {{ key }}
   </div>
 </el-card>
</div>
</template>
<script>
export default {
  name: "export_user_key.vue",
  data(){
   return{
     key:'',
     authMemberList:[],
     userId:''
   }
  },
  created() {
    this.getAuthMember()
  },
  methods:{
    async getAuthMember(){
      const { data } = await this.$http.post("/admin/AuthMember/getList?page=1&pageSize=100");
      this.authMemberList=data.data.list
    },
    async getKey(){
      if(this.userId){
        const { data } = await this.$http.post("/admin/ExportUserKey/getKey?userId="+this.userId);
        console.log(data)
        this.key=data.data.token
      }else {
        this.$message('请选择管理员')
      }
    },
    handleRead(){
      navigator.clipboard.writeText(this.key)
          .then(() => {
            this.$message.success("复制成功");
            this.key=''
            this.userId=''
          })
          .catch((err) => {
            // 复制失败
            console
                .error("复制失败");
          });
    }
  }
}
</script>
<style scoped lang="less">

</style>